@use "@angular/material" as mat;
//@import "@angular/material/theming";
// Plus imports for other components in your app.

// ng-select theme
@import "@ng-select/ng-select/themes/material.theme.css";
@include mat.core();
$paper-app-primary: mat.define-palette(mat.$indigo-palette);
$paper-app-accent: mat.define-palette(mat.$blue-palette);
$paper-app-warn: mat.define-palette(mat.$red-palette);
$paper-app-theme: mat.define-light-theme(
  $paper-app-primary,
  $paper-app-accent,
  $paper-app-warn
);
@include mat.all-component-themes($paper-app-theme);

// import bootstrap with namespace
@import "bootstrap/scss/bootstrap.scss";
