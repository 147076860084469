.answerFieldLabel {
  position: absolute;
  color: red;
  white-space: nowrap;
  margin-left: -4px;
  margin-top: -3px;
  font-size: 9pt;
  font-weight: bold;
  pointer-events: none;
}

/* checkerboard background to prove image is transparent */
.checkerboard {
  background-image: linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

/* Forms */
.mat-radio-label-content {
  padding: 10px 10px 10px 5px !important;
  min-width: 50px;
}

.mat-checkbox-label {
  padding: 10px 10px 10px 5px !important;
  min-width: 50px;
}

fieldset {
  border: 2px solid rgba(68, 68, 68, 0.2);
}

fieldset>legend>strong {
  font-weight: 300;
  /* color: rgba(68, 68, 68, .7); */
  font-size: 16px;
  margin-top: 10px;
  padding: 5px;
  border-bottom: 2px solid rgba(68, 68, 68, 0.08);
}

/* Fill display with a given element */
.fillDisplay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: white;
  z-index: 10;
  overflow: scroll;
}

.blackBar {
  background: black !important;
  color: white !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.blackBar button.mat-button-small {
  background-color: black !important;
  color: white;
}

.faux-mat-label {
  margin-top: 20px;
  margin-bottom: -22px;
  transform-origin: 0 0;
  color: rgba(0, 0, 0, .54);
  display: block;
  transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.001px);
}

.mat-contrast {
  background-color: rgba(0, 0, 0, .6);
  color: #fff;
}

/* Fix width on forms after material update 2.0.0-beta10 */
.mat-input-container.width100 {
  width: 100%;
}

.mat-input-container.uk-form-width-large {
  width: 500px;
}

.inputOther {
  margin: 10px 0;
}

/* Utility */
.width100 {
  width: 100% !important;
}

.width95 {
  width: 95% !important;
}

.width90 {
  width: 90% !important;
}

.width80 {
  width: 80% !important;
}

.width75 {
  width: 75% !important;
}

.width70 {
  width: 70% !important;
}

.width60 {
  width: 60% !important;
}

.width50 {
  width: 50% !important;
}

.width45 {
  width: 45% !important;
}

.width40 {
  width: 40% !important;
}

.width30 {
  width: 30% !important;
}

.width25 {
  width: 25% !important;
}

.width20 {
  width: 20% !important;
}

.width15 {
  width: 15% !important;
}

.width10 {
  width: 10% !important;
}

.width5 {
  width: 5% !important;
}

.float-left {
  float: left;
}

small {
  font-size: small;
}

/* Icons Fix */
.material-icons {
  vertical-align: middle;
}

/* Global Styles */

.sebm-google-map-container {
  width: 450px;
  height: 450px;
}

.mat-whiteframe-z2 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

html, body {
  font-size: 16px !important;
  background: #efefef !important;
  background-color: #f8f8f8 !important;
  font-family: "Roboto", "Helvetica Neue", sans-serif !important;
  padding: 0 !important;
  margin: 0 !important;
}

body.mobile {
  overflow: hidden;
}

body.web {
  overflow: visible;
  position: initial;
}

[mat-tab-nav-bar],
.mat-tab-header {
  border-bottom: 1px solid #e0e0e0;
}

.mat-tab-label:focus {
  background-color: rgba(179, 229, 252, 0.3);
}

.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.fill-remaining-space {
  flex: 1 1 auto;
}

.paper-nav button {
  min-width: inherit;
}

@media (min-width: 600px) {
  .psoc-label {
    display: inline-block;
    width: 140px;
    text-align: right;
  }
}

.psoc-label {
  font-weight: bold;
  margin-right: 10px;
}

/* material spec says min width should be 88 but looks odd on small buttons */
.mat-button-small {
  min-width: inherit !important;
}

/* Utility classes stolen from UIKIt */

/* Horizontal rules
 ========================================================================== */
/*
 * 1. Address differences between Firefox and other browsers.
 * 2. Style
 */
hr {
  /* 1 */
  box-sizing: content-box;
  height: 0;
  /* 2 */
  margin: 10px 0;
  border: 0;
  border-top: 2px solid rgba(68, 68, 68, 0.08);
}

.uk-article-divider {
  margin-bottom: 25px;
  border-color: rgba(68, 68, 68, 0.08);
}

*+.uk-article-divider {
  margin-top: 25px;
}

/* ========================================================================
   Component: Utility
 ========================================================================== */
/* Container
 ========================================================================== */
.uk-container {
  box-sizing: border-box;
  max-width: 980px;
  padding: 0 20px;
}

/* Large screen and bigger */
@media (min-width: 1220px) {
  .uk-container {
    max-width: 1200px;
    padding: 0 30px;
  }
}

/*
 * Micro clearfix
 */
.uk-container:before,
.uk-container:after {
  content: "";
  display: table;
}

.uk-container:after {
  clear: both;
}

.uk-group:before,
.uk-group:after {
  content: "";
  display: table;
  clear: both;
}

/*
 * Center container
 */
.uk-container-center {
  margin-left: auto;
  margin-right: auto;
}

/* ========================================================================
   Component: Text
 ========================================================================== */
/* Size modifiers
 ========================================================================== */
.uk-text-small {
  font-size: 11px;
  line-height: 16px;
}

.uk-text-large {
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
}

.uk-text-huge {
  font-size: 70px;
}

/* Weight modifiers
 ========================================================================== */
.uk-text-bold {
  font-weight: bold;
}

/* Color modifiers
 ========================================================================== */
.uk-text-muted {
  color: #999 !important;
}

.uk-text-primary {
  color: #3358FF !important;
}

.uk-text-success {
  color: #659f13 !important;
}

.uk-text-warning {
  color: #e28327 !important;
}

.uk-text-danger {
  color: #d85030 !important;
}

.uk-text-contrast {
  color: #fff !important;
}

/* Alignment modifiers
 ========================================================================== */
.uk-text-left {
  text-align: left !important;
}

.uk-text-right {
  text-align: right !important;
}

.uk-text-center {
  text-align: center !important;
}

.uk-text-justify {
  text-align: justify !important;
}

.uk-text-top {
  vertical-align: top !important;
}

.uk-text-middle {
  vertical-align: middle !important;
}

.uk-text-bottom {
  vertical-align: bottom !important;
}

/* Only tablets portrait and smaller */
@media (max-width: 959px) {
  .uk-text-center-medium {
    text-align: center !important;
  }

  .uk-text-left-medium {
    text-align: left !important;
  }
}

/* Phone landscape and smaller */
@media (max-width: 767px) {
  .uk-text-center-small {
    text-align: center !important;
  }

  .uk-text-left-small {
    text-align: left !important;
  }
}

/* Wrap modifiers
 ========================================================================== */
/*
 * Prevent text from wrapping onto multiple lines
 */
.uk-text-nowrap {
  white-space: nowrap;
}

/*
 * Prevent text from wrapping onto multiple lines, and truncate with an ellipsis
 */
.uk-text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*
 * Break strings if their length exceeds the width of their container
 */
.uk-text-break {
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

/* Transform modifiers
 ========================================================================== */
.uk-text-capitalize {
  text-transform: capitalize !important;
}

.uk-text-lowercase {
  text-transform: lowercase !important;
}

.uk-text-uppercase {
  text-transform: uppercase !important;
}

/* ========================================================================
   Component: Text
 ========================================================================== */
/* Size modifiers
 ========================================================================== */
.uk-text-small {
  font-size: 12px;
  line-height: 18px;
}

.uk-text-large {
  font-size: 19px;
  line-height: 25px;
  font-weight: normal;
}

/* Weight modifiers
 ========================================================================== */
.uk-text-bold {
  font-weight: bold;
}

/* Color modifiers
 ========================================================================== */
.uk-text-muted {
  color: rgba(68, 68, 68, 0.25) !important;
}

.uk-text-primary {
  color: #3358FF !important;
}

.uk-text-success {
  color: #8cc14c !important;
}

.uk-text-warning {
  color: #faa732 !important;
}

.uk-text-danger {
  color: #da314b !important;
}

.uk-text-contrast {
  color: #ffffff !important;
}

/* Alignment modifiers
 ========================================================================== */
.uk-text-left {
  text-align: left !important;
}

.uk-text-right {
  text-align: right !important;
}

.uk-text-center {
  text-align: center !important;
}

.uk-text-justify {
  text-align: justify !important;
}

.uk-text-top {
  vertical-align: top !important;
}

.uk-text-middle {
  vertical-align: middle !important;
}

.uk-text-bottom {
  vertical-align: bottom !important;
}

/* Only tablets portrait and smaller */
@media (max-width: 959px) {
  .uk-text-center-medium {
    text-align: center !important;
  }

  .uk-text-left-medium {
    text-align: left !important;
  }
}

/* Phone landscape and smaller */
@media (max-width: 767px) {
  .uk-text-center-small {
    text-align: center !important;
  }

  .uk-text-left-small {
    text-align: left !important;
  }

  .paper-nav button {
    padding: 0 0 0 5px;
  }
}

/* Wrap modifiers
 ========================================================================== */
/*
 * Prevent text from wrapping onto multiple lines
 */
.uk-text-nowrap {
  white-space: nowrap;
}

/*
 * Prevent text from wrapping onto multiple lines, and truncate with an ellipsis
 */
.uk-text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*
 * Break strings if their length exceeds the width of their container
 */
.uk-text-break {
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

/* ========================================================================
   Component: Utility
 ========================================================================== */
/* Container
 ========================================================================== */
.uk-container {
  box-sizing: border-box;
  max-width: 980px;
  padding: 0 25px;
}

/* Large screen and bigger */
@media (min-width: 1220px) {
  .uk-container {
    max-width: 1200px;
    padding: 0 35px;
  }
}

/*
 * Micro clearfix
 */
.uk-container:before,
.uk-container:after {
  content: "";
  display: table;
}

.uk-container:after {
  clear: both;
}

/*
 * Center container
 */
.uk-container-center {
  margin-left: auto;
  margin-right: auto;
}

/* Clearing
 ========================================================================== */
/*
 * Micro clearfix
* `table-cell` is used with `:before` because `table` creates a 1px gap when it becomes a flex item, only in Webkit
 * `table` is used again with `:after` because `clear` only works with block elements.
 * Note: `display: block` with `overflow: hidden` is currently not working in the latest Safari
 */
.uk-clearfix:before {
  content: "";
  display: table-cell;
}

.uk-clearfix:after {
  content: "";
  display: table;
  clear: both;
}

/*
 *  Create a new block formatting context
 */
.uk-nbfc {
  overflow: hidden;
}

.uk-nbfc-alt {
  display: table-cell;
  width: 10000px;
}

/* Alignment of block elements
 ========================================================================== */
/*
 * Float blocks
 * 1. Prevent content overflow on small devices
 */
.uk-float-left {
  float: left;
}

.uk-float-right {
  float: right;
}

/* 1 */
[class*='uk-float-'] {
  max-width: 100%;
}

/* Alignment of images and objects
 ========================================================================== */
/*
 * Alignment
 */
[class*='uk-align-'] {
  display: block;
  margin-bottom: 15px;
}

.uk-align-left {
  margin-right: 15px;
  float: left;
}

.uk-align-right {
  margin-left: 15px;
  float: right;
}

/* Tablet and bigger */
@media (min-width: 768px) {
  .uk-align-medium-left {
    margin-right: 15px;
    float: left;
  }

  .uk-align-medium-right {
    margin-left: 15px;
    float: right;
  }
}

.uk-align-center {
  margin-left: auto;
  margin-right: auto;
}

/* Vertical alignment
 ========================================================================== */
/*
 * Remove whitespace between child elements when using `inline-block`
 */
.uk-vertical-align {
  font-size: 0.001px;
}

/*
 *  The `uk-vertical-align` container needs a specific height
 */
.uk-vertical-align:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

/*
 * Sub-object which can have any height
 * 1. Reset whitespace hack
 */
.uk-vertical-align-middle,
.uk-vertical-align-bottom {
  display: inline-block;
  max-width: 100%;
  /* 1 */
  font-size: 1rem;
}

.uk-vertical-align-middle {
  vertical-align: middle;
}

.uk-vertical-align-bottom {
  vertical-align: bottom;
}

/* Height
 ========================================================================== */
/*
 * More robust if padding and border are used
 */
[class*='uk-height'] {
  box-sizing: border-box;
}

/*
 * Useful to extend the `html` and `body` element to the full height of the page.
 */
.uk-height-1-1 {
  height: 100%;
}

/*
 * Useful to create image teasers
 */
.uk-height-viewport {
  height: 100vh;
  min-height: 600px;
}

/* Responsive objects
 * Note: Images are already responsive by default, see Base component
 ========================================================================== */
/*
 * 1. Corrects `max-width` and `max-height` behavior if padding and border are used
 */
.uk-responsive-width,
.uk-responsive-height {
  box-sizing: border-box;
}

/*
 * Responsiveness: Sets a maximum width relative to the parent and auto scales the height
 * `important` needed to override `uk-img-preserve img`
 */
.uk-responsive-width {
  max-width: 100% !important;
  height: auto;
}

/*
 * Responsiveness: Sets a maximum height relative to the parent and auto scales the width
 * Only works if the parent element has a fixed height.
 */
.uk-responsive-height {
  max-height: 100%;
  width: auto;
}

/* Margin
 ========================================================================== */
/*
 * Create a block with the same margin of a paragraph
 * Add margin if adjacent element
 */
.uk-margin-center {
  margin: 0 auto;
}

.uk-margin {
  margin-bottom: 15px;
}

*+.uk-margin {
  margin-top: 15px;
}

.uk-margin-top {
  margin-top: 15px !important;
}

.uk-margin-bottom {
  margin-bottom: 15px !important;
}

.uk-margin-left {
  margin-left: 15px !important;
}

.uk-margin-right {
  margin-right: 15px !important;
}

/*
 * Larger margins
 */
.uk-margin-large {
  margin-bottom: 50px;
}

*+.uk-margin-large {
  margin-top: 50px;
}

.uk-margin-large-top {
  margin-top: 50px !important;
}

.uk-margin-large-bottom {
  margin-bottom: 50px !important;
}

.uk-margin-large-left {
  margin-left: 50px !important;
}

.uk-margin-large-right {
  margin-right: 50px !important;
}

/*
 * Smaller margins
 */
.uk-margin-small {
  margin-bottom: 5px;
}

*+.uk-margin-small {
  margin-top: 5px;
}

.uk-margin-small-top {
  margin-top: 5px !important;
}

.uk-margin-small-bottom {
  margin-bottom: 5px !important;
}

.uk-margin-small-left {
  margin-left: 5px !important;
}

.uk-margin-small-right {
  margin-right: 5px !important;
}

/*
 * Remove margins
 */
.uk-margin-remove {
  margin: 0 !important;
}

.uk-margin-top-remove {
  margin-top: 0 !important;
}

.uk-margin-bottom-remove {
  margin-bottom: 0 !important;
}

/* Padding
 ========================================================================== */
.uk-padding-remove {
  padding: 0 !important;
}

.uk-padding-top-remove {
  padding-top: 0 !important;
}

.uk-padding-bottom-remove {
  padding-bottom: 0 !important;
}

.uk-padding-vertical-remove {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.uk-padding-horizontal-remove {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* Border
 ========================================================================== */
.uk-border-circle {
  border-radius: 50%;
}

.uk-border-rounded {
  border-radius: 5px;
}

/* Headings
 ========================================================================== */
.uk-heading-large {
  font-size: 36px;
  line-height: 42px;
}

/* Tablet and bigger */
@media (min-width: 768px) {
  .uk-heading-large {
    font-size: 52px;
    line-height: 64px;
  }
}

/* Display
 ========================================================================== */
/*
 * Display
 * 1. Required if child is a responsive image
 */
.uk-display-block {
  display: block !important;
}

.uk-display-inline {
  display: inline !important;
}

.uk-display-inline-block {
  display: inline-block !important;
  /* 1 */
  max-width: 100%;
}

/*
 * Visibility
 * Avoids setting display to `block` so it works also with `inline-block` and `table`
 */
/* Desktop and bigger */
@media (min-width: 960px) {
  .uk-visible-small {
    display: none !important;
  }

  .uk-visible-medium {
    display: none !important;
  }

  .uk-hidden-large {
    display: none !important;
  }
}

/* Tablets portrait */
@media (min-width: 768px) and (max-width: 959px) {
  .uk-visible-small {
    display: none !important;
  }

  .uk-visible-large {
    display: none !important;
  }

  .uk-hidden-medium {
    display: none !important;
  }
}

/* Phone landscape and smaller*/
@media (max-width: 767px) {
  .uk-visible-medium {
    display: none !important;
  }

  .uk-visible-large {
    display: none !important;
  }

  .uk-hidden-small {
    display: none !important;
  }
}

/* Remove from the flow and screen readers on any device */
.uk-hidden {
  display: none !important;
  visibility: hidden !important;
}

/* It's hidden, but still affects layout */
.uk-invisible {
  visibility: hidden !important;
}

/* Show on hover */
.uk-visible-hover:hover .uk-hidden,
.uk-visible-hover:hover .uk-invisible {
  display: block !important;
  visibility: visible !important;
}

.uk-visible-hover-inline:hover .uk-hidden,
.uk-visible-hover-inline:hover .uk-invisible {
  display: inline-block !important;
  visibility: visible !important;
}

/* Hide on touch */
.uk-touch .uk-hidden-touch,
.uk-notouch .uk-hidden-notouch {
  display: none !important;
}

/* Size sub-modifiers
 ========================================================================== */
/*
 * Fixed widths
 * Different widths for mini sized `input` and `select` elements
 */
input.uk-form-width-mini {
  width: 40px;
}

select.uk-form-width-mini {
  width: 65px;
}

.uk-form-width-small {
  width: 130px;
}

.uk-form-width-medium {
  width: 200px;
}

.uk-form-width-large {
  width: 500px;
}

/* Modifier: `uk-description-list-line`
 ========================================================================== */
dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

.uk-description-list-line>dt {
  font-weight: normal;
}

.uk-description-list-line>dt:nth-child(n+2) {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 2px solid rgba(68, 68, 68, 0.08);
}

.uk-description-list-line>dd {
  color: rgba(68, 68, 68, 0.25);
}


/* ========================================================================
   Component: List
 ========================================================================== */
.uk-list {
  padding: 0;
  list-style: none;
}

/*
 * Micro clearfix to make list more robust
 */
.uk-list>li:before,
.uk-list>li:after {
  content: "";
  display: table;
}

.uk-list>li:after {
  clear: both;
}

/*
 * Remove margin from the last-child
 */
.uk-list>li> :last-child {
  margin-bottom: 0;
}

/*
 * Nested lists
 */
.uk-list ul {
  margin: 0;
  padding-left: 20px;
  list-style: none;
}

/* Modifier: `uk-list-line`
 ========================================================================== */
.uk-list-line>li:nth-child(n+2) {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 2px solid rgba(68, 68, 68, 0.08);
}

/* Modifier: `uk-list-striped`
 ========================================================================== */
.uk-list-striped>li {
  padding: 5px 5px;
}

.uk-list-striped>li:nth-of-type(odd) {
  background: rgba(34, 34, 34, 0.08);
}

/* Modifier: `uk-list-space`
 ========================================================================== */
.uk-list-space>li:nth-child(n+2) {
  margin-top: 10px;
}

.uk-list>li>a {
  text-decoration: none;
}

/* ========================================================================
   Component: Comment
 ========================================================================== */
/* Sub-object `uk-comment-header`
 ========================================================================== */
.uk-comment-header {
  margin-bottom: 15px;
}

/*
 * Micro clearfix
 */
.uk-comment-header:before,
.uk-comment-header:after {
  content: "";
  display: table;
}

.uk-comment-header:after {
  clear: both;
}

/* Sub-object `uk-comment-avatar`
 ========================================================================== */
.uk-comment-avatar {
  margin-right: 15px;
  float: left;
}

/* Sub-object `uk-comment-title`
 ========================================================================== */
.uk-comment-title {
  margin: 5px 0 0 0;
  font-size: 20px;
  line-height: 26px;
}

/* Sub-object `uk-comment-meta`
 ========================================================================== */
.uk-comment-meta {
  margin: 2px 0 0 0;
  font-size: 13px;
  line-height: 17px;
  color: #9a9a9c;
  letter-spacing: 1px;
  /*font-style: italic;*/
}

.uk-comment-meta.uk-subnav>li,
.uk-comment-meta.uk-subnav>li>a,
.uk-comment-meta.uk-subnav>li>span {
  font-size: 13px;
}

/* Sub-object `uk-comment-body`
 ========================================================================== */
/*
 * Remove margin from the last-child
 */
.uk-comment-body> :last-child {
  margin-bottom: 0;
}

/* Sub-object `uk-comment-list`
 ========================================================================== */
.uk-comment-list {
  padding: 0;
  list-style: none;
}

.uk-comment-list .uk-comment+ul {
  margin: 15px 0 0 0;
  list-style: none;
}

.uk-comment-list>li:nth-child(n+2),
.uk-comment-list .uk-comment+ul>li:nth-child(n+2) {
  margin-top: 15px;
}

/* Tablet and bigger */
@media (min-width: 768px) {
  .uk-comment-list .uk-comment+ul {
    padding-left: 100px;
  }
}

/* Modifier `uk-comment-primary`
 ========================================================================== */
.uk-comment-primary .uk-comment-header {
  padding: 15px;
  border: 2px solid rgba(68, 68, 68, 0.08);
}

/* ========================================================================
   Component: Table
 ========================================================================== */
/*
 * 1. Remove most spacing between table cells.
 * 2. Block element behavior
 * 3. Style
 */
.uk-table {
  /* 1 */
  border-collapse: collapse;
  border-spacing: 0;
  /* 2 */
  width: 100%;
  /* 3 */
  margin-bottom: 15px;
}

/*
 * Add margin if adjacent element
 */
*+.uk-table {
  margin-top: 15px;
}

.uk-table th,
.uk-table td {
  padding: 8px 8px;
}

/*
 * Set alignment
 */
.uk-table th {
  text-align: left;
}

.uk-table td {
  vertical-align: top;
}

.uk-table thead th {
  vertical-align: bottom;
}

/*
 * Caption and footer
 */
.uk-table caption,
.uk-table tfoot {
  font-size: 13px;
  font-style: italic;
}

.uk-table caption {
  text-align: left;
  color: rgba(68, 68, 68, 0.25);
}

/*
 * Active State
 */
.uk-table tbody tr.uk-active {
  background: rgba(34, 34, 34, 0.04);
}

/* Sub-modifier: `uk-table-middle`
 ========================================================================== */
.uk-table-middle,
.uk-table-middle td {
  vertical-align: middle !important;
}

/* Modifier: `uk-table-striped`
 ========================================================================== */
.uk-table-striped tbody tr:nth-of-type(odd) {
  background: rgba(34, 34, 34, 0.1);
}

/* Modifier: `uk-table-condensed`
 ========================================================================== */
.uk-table-condensed td {
  padding: 4px 8px;
}

/* Modifier: `uk-table-hover`
 ========================================================================== */
.uk-table-hover tbody tr:hover {
  background: rgba(34, 34, 34, 0.04);
}

.referral-ped-select {
  width: 15% !important;
}

.narrative-val {
  float: right;
  min-width: 46%;
}

.flex-item {
  padding-right: 15px;
}

.mat-tooltip {
  width: auto;
}

/* data table styles */
.list-container {
  clear: both;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  /* max-height: 500px; */
}

.list-header {
  min-height: 64px;
  display: flex;
  align-items: center;
  padding-left: 24px;
}

/*chat styles*/
.chat-form {
  padding: 10px;
}

.chat-container {
  max-height: 320px;
  overflow: auto;
}

.chat-container {
  margin-bottom: 12px;
  position: relative;
}

.chat-author {
  font-size: 12.5px;
  font-weight: 600;
  height: 21px;
  line-height: 21px;
}

.author-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 1px;
}

.chat-bubble {
  max-width: 95%;
  padding: 8px 10px 8px 10px;
  border-radius: 8px;
  position: relative;
  margin-bottom: 8px;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
}

.chat-message {
  font-size: 14px;
  line-height: 19px;
  word-wrap: break-word;
  color: #262626;
}

.chat-footer {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 15px;
}

.chat-card {
  min-height: 400px;
}

.chat-input {
  padding: 0 5px;
  box-sizing: border-box;
  border-radius: 6px;
}

.chat-inputI {
  padding-left: 20px;
}

.chat-button {
  margin-top: 20px;
}

#back-button {
  position: absolute;
  right: 20px;
  top: 90px;
}

/*appointments styles*/
.remove-item-btn {
  margin-left: auto !important;
}

.dialog-content-height {
  min-height: 75vh;
}

#barcode-scanner-wrapper {
  width: 30px;
  display: inline-grid;
  align-content: center;
  position: relative;
  top: 5px;
  left: 10px;
}

#barcode-pane {
  padding: 5px !important;
}

.next-button {
  background-color:#1A5D1A !important; 
  color:#ffffff !important;
}

.next-button:disabled,
.next-button[disabled]{
  background-color: #cccccc !important;
  color: #666666 !important;
}